/* eslint-disable class-methods-use-this, prefer-destructuring */
import Cookies from 'js-cookie';
import { isDesktop } from './deviceType';

const getOrderUuid = () => Cookies.get('coa') || '';

/**
 * Get context object for FE analytics events
 */
const getContext = () => {
  const orderUuid = getOrderUuid();
  const deviceType = isDesktop() ? 'DESKTOP' : 'MOBILE';

  return {
    context: {
      ip: '0.0.0.0',
    },
    deviceType,
    orderUuid,
    origin: 'MGO',
  };
};


export const track = async (event, payload = {}) => {
  const trackEventCb = () => window.analytics.track(
    event,
    { ...payload },
    { ...getContext() },
  );
  trackEventCb();
};
